import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import useGlobalStyles from '../../styles/index';
import {
  getActiveMarker,
  getCurrentLocation,
  getLocationsById,
  getSelectedEndDate,
  getSelectedStartDate,
  getSensorsById,
  getSensorsByLocId,
  getSensorsByVarName,
} from '../../state/selectors';

import { VarName, varNameDetails } from '../../utils/varNames';
import DateRangePicker from '../../components/DateRangePicker';
import { SensorHistoryPlotItem } from '../../Widgets/Plots/plotCommon';
import DataStore from '../../services/dataStore';
import ColouredLinePlot from '../../Widgets/Plots/ColouredLinePlot';
import { themeProps } from '../../styles/theme';
import Feedback from './Feedback/Feedback';
import { getDataValueString } from '../../Widgets/HelperComponents/DataValueString';
import { getDataBandParams, varNameBandParams } from '../../utils/dataBandParams';
import WeatherService, { WeatherData } from '../../services/weatherService';
import { setSelectedStartDate, setSelectedEndDate } from '../../state/actions';
import { DataItem } from '../../services/api';
import { getEndOfDay, getStartOfDay } from '../../utils/functions';
import BackBtnIcon from '../../styles/icons/BackBtn';
import { appSwitchBtnHandler } from '../../Shell/helpers';
import { bandRangeStr, bandStyles } from '../../components/varNameDescriptions';
import LFSwitchLogoIcon from '../../styles/icons/LFSwitchLogoIcon';

function AppDashboard() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dataStore = DataStore.getInstance();
  const globalClasses = useGlobalStyles();
  const { sensorId } = useParams() as { sensorId: string };

  const activeMarker = useSelector(getActiveMarker);
  const [sensorData, setSensorData] = useState<SensorHistoryPlotItem>();
  const sensorsById = useSelector(getSensorsById);
  const locationsById = useSelector(getLocationsById);
  const startDate = useSelector(getSelectedStartDate);
  const endDate = useSelector(getSelectedEndDate);
  const currentLocation = useSelector(getCurrentLocation);
  const sensorsByVarName = useSelector(getSensorsByVarName).get(activeMarker);
  const locSensors = useSelector(getSensorsByLocId).get(currentLocation);
  const bandParams = varNameBandParams[activeMarker] ?? [];

  const weatherService = WeatherService.getInstance();

  const [showFeedbackPage, setShowFeedbackPage] = useState(false);
  const [outsideWeather, setOutsideWeather] = useState<number>();
  const [showBandDialog, setShowBandDialog] = useState<boolean>(false);

  useEffect(() => setOutsideWeather(undefined), [currentLocation]);

  const showOutsideWeather =
    activeMarker === VarName.TemperatureC || activeMarker === VarName.RelativeHumidity;

  const selectedSensorValue =
    sensorsById.get(sensorId)?.data?.find((item) => item.varName === activeMarker)?.value ?? 0;

  // band details for selected sensor
  const { description, label } = getDataBandParams(activeMarker, selectedSensorValue);

  const avgReading = useMemo(() => {
    let avgValue;
    if (showOutsideWeather) {
      if (outsideWeather) avgValue = outsideWeather;
    } else {
      const locVarValues: DataItem[] = [];
      const activeVarLocSensors =
        locSensors ?? [].filter((item) => sensorsByVarName ?? [].includes(item));
      activeVarLocSensors.forEach((id) => {
        const dataItem = sensorsById.get(id)?.data?.find((item) => item.varName === activeMarker);
        if (dataItem) locVarValues.push(dataItem);
      });

      avgValue = Math.trunc(
        locVarValues.reduce((acc, curr) => acc + (curr?.value ?? 0), 0) / locVarValues.length
      );
    }
    return avgValue;
  }, [activeMarker, locSensors, outsideWeather, sensorsById, sensorsByVarName, showOutsideWeather]);

  const updateOutsideWeather = (data: WeatherData) => {
    if (activeMarker === VarName.TemperatureC && data.main?.temp)
      setOutsideWeather(Math.round(data.main?.temp));
    else if (activeMarker === VarName.RelativeHumidity && data.main?.humidity)
      setOutsideWeather(Math.round(data.main?.humidity));
  };

  useEffect(() => {
    if (showOutsideWeather && outsideWeather === undefined) {
      let position;
      const locPos = locationsById.get(currentLocation)?.position;
      const sensorPos = sensorsById.get(sensorId)?.position;

      if (locPos) position = locPos;
      else if (sensorPos) position = sensorPos;
      else if (locSensors) {
        for (let i = 0; i < locSensors.length; i++) {
          const locSensorPos = sensorsById.get(locSensors[i])?.position;
          if (locSensorPos) {
            position = locSensorPos;
            break;
          }
        }
      }

      if (position && position.lat && position.lng) {
        weatherService
          .getCurrentWeather(currentLocation, position.lat, position.lng)
          .then((res) => {
            updateOutsideWeather(res);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentLocation,
    locSensors,
    locationsById,
    outsideWeather,
    sensorId,
    sensorsById,
    showOutsideWeather,
  ]);

  useEffect(() => {
    dataStore
      .getHistory(
        sensorId,
        activeMarker,
        new Date(startDate).getTime(),
        new Date(endDate).getTime()
      )
      .then((history) => {
        const dataItem: SensorHistoryPlotItem = {
          varName: activeMarker,
          sensorId,
          sensorName: sensorsById.get(sensorId)?.name ?? '',
          history: {
            time: history.map(({ time }) => time),
            value: history.map(({ value }) => value),
          },
        };
        setSensorData(dataItem);
      });
  }, [activeMarker, dataStore, endDate, sensorId, sensorsById, startDate]);

  function handleRelayout(event: Plotly.PlotRelayoutEvent) {
    if (event['xaxis.range[0]'] && event['xaxis.range[1]']) {
      let newStart = new Date(event['xaxis.range[0]']);
      let newEnd = new Date(event['xaxis.range[1]']);
      // Don't dispatch if it is a zoom into current data, only if the new range is larger (pan)
      if (dayjs(newStart).isBefore(dayjs(startDate)) || dayjs(newEnd).isAfter(dayjs(endDate))) {
        // If dragged to the future limit to now and keep range from drag
        const now = new Date();
        if (newEnd > now) {
          const diff = Math.max(newEnd.getTime() - now.getTime(), 24 * 3600 * 1000);
          newEnd = getEndOfDay(now);
          newStart = getStartOfDay(new Date(newStart.getTime() - diff));
        }
        dispatch(setSelectedStartDate(newStart));
        dispatch(setSelectedEndDate(newEnd));
      }
    }
  }

  const showReadings = (value: number | undefined) => (
    <Box
      sx={{ background: theme.palette.primary.main, marginTop: '5px' }}
      className={globalClasses.appMenuBtn}
    >
      <Typography
        variant="h3"
        sx={{
          alignSelf: 'center',
          color:
            value !== undefined
              ? getDataBandParams(activeMarker, value)?.color
              : theme.palette.text.primary,
        }}
      >
        {value !== undefined ? (
          <>
            {getDataValueString(value, activeMarker, undefined, undefined, false)}
            <span style={{ fontSize: themeProps.textSize.h4 }}>
              {' '}
              {varNameDetails[activeMarker].metric}
            </span>
          </>
        ) : (
          '-'
        )}
      </Typography>
    </Box>
  );

  return (
    <div className={globalClasses.bodyContent} style={{ padding: '10px' }}>
      <Dialog
        open={showBandDialog}
        style={{ textAlign: 'center' }}
        onClose={() => setShowBandDialog(false)}
      >
        <DialogContent>
          <ul style={bandStyles.listStyle}>
            {bandParams.map((band) => (
              <li style={bandStyles.listItemStyle} key={band.label}>
                <div
                  style={{
                    ...bandStyles.bandIndicator,
                    backgroundColor: band.color,
                  }}
                />
                {band.label} ({bandRangeStr(bandParams).get(band.label)}{' '}
                {varNameDetails[activeMarker].metric}) {band.description && `- ${band.description}`}
              </li>
            ))}
          </ul>
          <Button
            variant={themeProps.btnVariant.default}
            sx={{
              margin: '10px',
              width: '50%',
              background: themeProps.colors.warningRed,
              border: 'none',
            }}
            onClick={() => setShowBandDialog(false)}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton onClick={() => navigate('/appSourceMenu')}>
          <BackBtnIcon />
        </IconButton>
        <Box sx={{ display: 'flex' }}>
          <Switch
            checked
            onClick={() => appSwitchBtnHandler(dispatch)}
            color="success"
            checkedIcon={<LFSwitchLogoIcon fontSize="small" />}
          />
        </Box>
      </Box>{' '}
      {!showFeedbackPage && (
        <Box>
          <Typography variant="h4" sx={{ textAlign: 'center', margin: '10px 0' }}>
            {varNameDetails[activeMarker].label}
          </Typography>

          <Grid container spacing={2} sx={{ textAlign: 'center', marginTop: '10px' }}>
            <Grid item xs={6}>
              <span>{sensorsById.get(sensorId)?.name ?? ' Sensor reading'}</span>
              {showReadings(selectedSensorValue)}
            </Grid>
            <Grid item xs={6}>
              <span>{showOutsideWeather ? 'Outside' : 'Average'} reading</span>
              {showReadings(avgReading)}
            </Grid>
          </Grid>
          <Grid container sx={{ alignItems: 'center', margin: '20px 0' }}>
            <Grid item xs={11}>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                {label} ({bandRangeStr(bandParams).get(label)} {varNameDetails[activeMarker].metric}
                ) {description && `- ${description}`}{' '}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Box onClick={() => setShowBandDialog(true)}>
                <HelpOutlineIcon sx={{}} />
              </Box>
            </Grid>
          </Grid>

          <Typography variant="h5" sx={{ textAlign: 'center', margin: '10px 0' }}>
            History{' '}
            <span style={{ fontSize: themeProps.textSize.small }}>
              ({sensorsById.get(sensorId)?.name ?? sensorId})
            </span>
          </Typography>
          <Box>
            <DateRangePicker showHoursSelection={false} />
          </Box>
          {sensorData && (
            <div className={globalClasses.sensorGraph}>
              <ColouredLinePlot data={sensorData} handleRelayout={(e) => handleRelayout(e)} />
            </div>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant={themeProps.btnVariant.default}
              sx={{
                marginTop: '2rem',
                borderRadius: '20px',
                background: theme.palette.primary.light,
                border: 'none',
              }}
              onClick={() => setShowFeedbackPage(true)}
            >
              How can we help you?
            </Button>
          </Box>
        </Box>
      )}
      {showFeedbackPage && (
        <Feedback
          sensorId={sensorId}
          sensorValue={parseFloat(selectedSensorValue.toFixed(1))}
          reset={() => setShowFeedbackPage(false)}
        />
      )}
    </div>
  );
}

export default AppDashboard;
